/*
Colors
*/

$surface: #FFFFFF;

$light: #FFFFFF;
$dark: #C3C3C3;

$textOnSurfaceH: rgba(0, 0, 0, 0.87);
$textOnSurfaceM: rgba(0, 0, 0, 0.60);
$textOnSurfaceD: rgba(0, 0, 0, 0.38);

$primary: #24413A;
$primary700: #1A322C;

$textOnPrimaryH: rgba(255, 255, 255, 1);
$textOnPrimaryM: rgba(255, 255, 255, 0.60);
$textOnPrimaryD: rgba(255, 255, 255, 0.38);

$secondary: #D8AC00;
$secondary700: #CE9B00;

$textOnSecondaryH: rgba(0, 0, 0, 1);
$textOnSecondaryM: rgba(0, 0, 0, 1);
$textOnSecondaryD: rgba(0, 0, 0, 0.38);

$whiteTextH: rgba(255, 255, 255, 1);
$whiteTextM: rgba(255, 255, 255, 0.60);
$whiteTextD: rgba(255, 255, 255, 0.38);

$blackTextH: rgba(0, 0, 0, 0.87);
$blackTextM: rgba(0, 0, 0, 0.60);
$blackTextD: rgba(0, 0, 0, 0.38);

$error: #B00020;
