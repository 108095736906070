@import 'variables';
@import 'mixins';

.header {
  padding: 0 1.5rem;
  margin-bottom: 3rem;
  position: relative;
  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 136px;
    height: 136px;
    margin: 0 auto;
    background-color: $primary;
    img {
      width: 124px;
      height: auto;
    }
  }
  &__button {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 1.5rem;
    right: 0.5rem;
  }
  @include media(lg) {
    margin-bottom: 4.5rem;
    &__button {
      right: 1.5rem;
    }
  }
}
