@import 'variables';

.logout {
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 8px;
  svg {
    fill: $blackTextD;
    transition: fill 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &:hover, &:focus {
    svg {
      fill: $blackTextM;
    }
  }
}
