@import 'mixins';

.container {
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1.5rem;
  margin-left: -1.5rem;
  &--no_gutters {
    margin-right: 0;
    margin-left: 0;
    & > .col, & > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto
.col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  &-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  &-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  &-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  &-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  &-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  &-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  &-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  &-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  &-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  &-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  &-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  &-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  &-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  &-offset-1 {
    margin-left: 8.333333%;
  }
  &-offset-2 {
    margin-left: 16.666667%;
  }
  &-offset-3 {
    margin-left: 25%;
  }
  &-offset-4 {
    margin-left: 33.333333%;
  }
  &-offset-5 {
    margin-left: 41.666667%;
  }
  &-offset-6 {
    margin-left: 50%;
  }
  &-offset-7 {
    margin-left: 58.333333%;
  }
  &-offset-8 {
    margin-left: 66.666667%;
  }
  &-offset-9 {
    margin-left: 75%;
  }
  &-offset-10 {
    margin-left: 83.333333%;
  }
  &-offset-11 {
    margin-left: 91.666667%;
  }
  @include media(sm) {
    &-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    &-sm-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    &-sm-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    &-sm-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    &-sm-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    &-sm-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    &-sm-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    &-sm-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    &-sm-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    &-sm-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    &-sm-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    &-sm-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    &-sm-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    &-sm-offset-1 {
      margin-left: 8.333333%;
    }
    &-sm-offset-2 {
      margin-left: 16.666667%;
    }
    &-sm-offset-3 {
      margin-left: 25%;
    }
    &-sm-offset-4 {
      margin-left: 33.333333%;
    }
    &-sm-offset-5 {
      margin-left: 41.666667%;
    }
    &-sm-offset-6 {
      margin-left: 50%;
    }
    &-sm-offset-7 {
      margin-left: 58.333333%;
    }
    &-sm-offset-8 {
      margin-left: 66.666667%;
    }
    &-sm-offset-9 {
      margin-left: 75%;
    }
    &-sm-offset-10 {
      margin-left: 83.333333%;
    }
    &-sm-offset-11 {
      margin-left: 91.666667%;
    }
  }
  @include media(md) {
    &-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    &-md-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    &-md-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    &-md-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    &-md-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    &-md-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    &-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    &-md-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    &-md-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    &-md-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    &-md-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    &-md-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    &-md-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    &-md-offset-1 {
      margin-left: 8.333333%;
    }
    &-md-offset-2 {
      margin-left: 16.666667%;
    }
    &-md-offset-3 {
      margin-left: 25%;
    }
    &-md-offset-4 {
      margin-left: 33.333333%;
    }
    &-md-offset-5 {
      margin-left: 41.666667%;
    }
    &-md-offset-6 {
      margin-left: 50%;
    }
    &-md-offset-7 {
      margin-left: 58.333333%;
    }
    &-md-offset-8 {
      margin-left: 66.666667%;
    }
    &-md-offset-9 {
      margin-left: 75%;
    }
    &-md-offset-10 {
      margin-left: 83.333333%;
    }
    &-md-offset-11 {
      margin-left: 91.666667%;
    }
  }
  @include media(lg) {
    &-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    &-lg-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    &-lg-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    &-lg-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    &-lg-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    &-lg-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    &-lg-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    &-lg-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    &-lg-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    &-lg-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    &-lg-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    &-lg-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    &-lg-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    &-lg-offset-1 {
      margin-left: 8.333333%;
    }
    &-lg-offset-2 {
      margin-left: 16.666667%;
    }
    &-lg-offset-3 {
      margin-left: 25%;
    }
    &-lg-offset-4 {
      margin-left: 33.333333%;
    }
    &-lg-offset-5 {
      margin-left: 41.666667%;
    }
    &-lg-offset-6 {
      margin-left: 50%;
    }
    &-lg-offset-7 {
      margin-left: 58.333333%;
    }
    &-lg-offset-8 {
      margin-left: 66.666667%;
    }
    &-lg-offset-9 {
      margin-left: 75%;
    }
    &-lg-offset-10 {
      margin-left: 83.333333%;
    }
    &-lg-offset-11 {
      margin-left: 91.666667%;
    }
  }
  @include media(xl) {
    &-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    &-xl-1 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    &-xl-2 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    &-xl-3 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    &-xl-4 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    &-xl-5 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    &-xl-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    &-xl-7 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    &-xl-8 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    &-xl-9 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    &-xl-10 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    &-xl-11 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    &-xl-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    &-xl-offset-1 {
      margin-left: 8.333333%;
    }
    &-xl-offset-2 {
      margin-left: 16.666667%;
    }
    &-xl-offset-3 {
      margin-left: 25%;
    }
    &-xl-offset-4 {
      margin-left: 33.333333%;
    }
    &-xl-offset-5 {
      margin-left: 41.666667%;
    }
    &-xl-offset-6 {
      margin-left: 50%;
    }
    &-xl-offset-7 {
      margin-left: 58.333333%;
    }
    &-xl-offset-8 {
      margin-left: 66.666667%;
    }
    &-xl-offset-9 {
      margin-left: 75%;
    }
    &-xl-offset-10 {
      margin-left: 83.333333%;
    }
    &-xl-offset-11 {
      margin-left: 91.666667%;
    }
  }
}
