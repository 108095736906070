@mixin media($media) {
  @if $media == 'sm' {
    @media (max-width: 767px) { @content; }
  }
  @else if $media == 'md' {
    @media (min-width: 768px) and (max-width: 1023px) { @content; }
  }
  @else if $media == 'lg' {
    @media (min-width: 1024px) { @content; }
  }
  @else if $media == 'xl' {
    @media (min-width: 1600px) { @content; }
  }
}
