@import 'mixins';
@import 'variables';

.subtitle {
  line-height: 1.2;
  font-size: 2rem;
  font-weight: 500;
  font-family: 'Cinzel', serif;
  margin-bottom: 2rem;
  color: $primary;
  @include media(lg) {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }
}
