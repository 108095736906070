@import 'variables';
@import 'mixins';

.section {
  position: relative;
  & ~ p {
    line-height: 1.4;
    margin-bottom: 3rem;
  }
  @include media(lg) {
    & ~ p {
      margin-bottom: 3.5rem;
    }
  }
}
