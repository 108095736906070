@import 'mixins';
@import 'variables';

.control {
  position: relative;
  padding: 0.875rem 0;
  margin: 1.5rem 0;
  transition: opacity 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
  label {
    font-size: 1rem;
    color: $textOnSurfaceM;
  }
  .input {
    label {
      position: absolute;
      left: 0;
      transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &--has-value{
      label {
        transform: scale(0.75) translateY(-1.875rem);
        transform-origin: top left;
      }
    }
    &__detail {
      width: 100%;
      height: 2px;
      background-color: $dark;
      position: absolute;
      bottom: 0;
      &::before {
        display: block;
        content: '';
        width: 100%;
        height: 2px;
        background-color: $primary;
        transition: transform 400ms cubic-bezier(0.165, 0.84, 0.44, 1);
        transform: scaleX(0);
        transform-origin: left;
      }
    }
    input {
      border: none;
      background-color: transparent;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 1rem;
      width: 100%;
      &:focus ~ .input__detail::before {
        transform: scaleX(1);
      }
      &:focus ~ label {
        transform: scale(0.75) translateY(-1.875rem);
        transform-origin: top left;
      }
    }
  }
  .button {
    width: 75%;
    margin: 0 auto;
    input {
      width: 100%;
      height: 56px;
      border-radius: 2px;
      background-color: $primary;
      color: rgba(255, 255, 255, 0.7);
      text-transform: uppercase;
      font-size: 0.9375rem;
      letter-spacing: 0.125em;
      border: none;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;
      transition: color 600ms cubic-bezier(0.165, 0.84, 0.44, 1), background-color 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
      &:hover, &:focus, &:active {
        color: rgba(255, 255, 255, 1);
        background-color: $primary700;
      }
    }
  }
  .file {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 220px;
    background-color: $dark;
    transition: color 600ms cubic-bezier(0.165, 0.84, 0.44, 1), background-color 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
    &__content {
      text-align: center;
    }
    &__icon {
      width: 5rem;
      height: auto;
      margin: 0 auto 0.5rem auto;
      svg {
        fill: rgba(0, 0, 0, 0.2);
      }
    }
    &__text {
      font-weight: 700;
      font-size: 0.9375rem;
    }
    &--active {
      background-color: #EDEDED;
    }
    &--reject {
      color: rgba(0, 0, 0, 0.3);
      background-color: #C50000;
    }
  }
  &--has-error {
    .input {
      &__detail {
        &::before {
          background-color: $error;
          transform: scaleX(1);
        }
      }
    }
  }
}
