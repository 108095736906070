@import 'mixins';
@import 'variables';

.only_mobile {
  @include media(lg) {
    display: none;
  }
}
.only_desktop {
  display: none;
  @include media(lg) {
    display: block;
  }
}

.centered {
  text-align: center;
}
.justified {
  text-align: justify;
}
.small {
  font-size: 0.875rem;
}
.link {
  text-decoration: none;
  color: $primary;
  &:hover {
    text-decoration: underline;
  }
}
