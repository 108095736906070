@import 'variables';

.status {
  margin: 1.5rem 0;
  padding: 1rem;
  text-align: center;
  color: $textOnSecondaryH;
  background-color: $secondary;
  span {
    font-weight: 700;
  }
  &--error {
    color: $whiteTextH;
    background-color: $error;
  }
  &-enter {
    transform: scaleY(0);
    &-active {
      transform: scaleY(1);
      transform-origin: top;
      transition: transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }
  &-exit {
    transform: scaleY(1);
    &-active {
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    }
  }
}
